import { type GetLoginUserDataResponse } from '~/feature/me/types'
import { getApiClient } from '~/lib/apiClientSingleton'

const getLoginUserData = async (): Promise<GetLoginUserDataResponse> => {
  const apiClient = getApiClient()
  return await apiClient.fetchFromAPI<Promise<GetLoginUserDataResponse>>({
    endpoint: '/me',
    options: apiClient.getRequestOptions(),
  })
}

export default getLoginUserData
