import { useQuery } from '@tanstack/react-query'
import { type ReactNode, createContext, useContext, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import getCompany from '~/feature/companies/api/getCompany'
import companiesQueryKeys from '~/feature/companies/queryKeys/companiesQueryKeys'
import getLoginUserData from '~/feature/me/api/getLoginUserData'
import loginUserDataQueryKeys from '~/feature/me/queryKeys/loginUserDataQueryKeys'
import type { UserRoles } from '../lib/role'
import type { Company } from '../models/company'

type SharedDataContextType = {
  isSharedDataLoading: boolean
  userRoles: UserRoles
  company: Company | null
  fetchData: () => void
}

const SharedDataContext = createContext<SharedDataContextType | undefined>(undefined)

export default SharedDataContext

interface SharedDataProviderProps {
  children: ReactNode
}

export const SharedDataProvider = ({ children }: SharedDataProviderProps) => {
  const [company, setCompany] = useState<Company | null>(null)
  const location = useLocation()
  const { company: company_code } = useParams<{ company: string }>()
  const pageCompanyCode = location.pathname.startsWith(`/companies/${company_code}`)
  const navigate = useNavigate()

  const useLoginUserData = useQuery({
    queryKey: loginUserDataQueryKeys.index(),
    queryFn: async () => {
      const data = await getLoginUserData()
      const isCompanyPerson = data.user.roles.includes('company_person')
      if (isCompanyPerson) {
        // NOTE: ログインユーザがシステム管理者の場合のみ、/me から所属企業を取得できる
        setCompany(data.company)
      }
      return data
    },
    meta: { isErrorHandling: false },
  })

  // NOTE: ログインユーザがシステム管理者の場合は、/me に所属企業の情報がないが、閲覧可能な企業のページの場合、その企業の情報を取得する
  const useCompany = useQuery({
    queryKey: companiesQueryKeys.detail(company_code ?? ''),
    queryFn: async () => {
      if (company_code === undefined) {
        setCompany(null)
        return null
      }
      try {
        const data = await getCompany(company_code)
        if (pageCompanyCode) {
          setCompany(data.company)
        } else {
          setCompany(null)
        }
        return data
      } catch (error) {
        if (pageCompanyCode) {
          // 企業情報を必要とするページで企業情報が取得できなかった場合はルートに移動
          navigate('/')
        }
        // root.tsxでエラー処理を行うためrethrow
        throw error
      }
    },
    enabled: useLoginUserData.data?.user.roles.includes('admin') === true,
  })

  return (
    <SharedDataContext.Provider
      value={{
        isSharedDataLoading: useLoginUserData.isLoading || useCompany.isLoading,
        userRoles: useLoginUserData.data?.user.roles || [],
        company,
        fetchData: useLoginUserData.refetch,
      }}
    >
      {children}
    </SharedDataContext.Provider>
  )
}

export const useSharedData = (): SharedDataContextType => {
  const context = useContext(SharedDataContext)
  if (context === undefined) {
    throw new Error('useSharedData must be used within an SharedDataProvider')
  }
  return context
}
